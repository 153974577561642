<template>
    <v-main>
        <template v-slot:sidebar>
            <v-section>
                <v-section-heading>
                    Reports
                </v-section-heading>

                <v-section-links :menu="menu" />
            </v-section>
        </template>

        <template v-slot:default="{ toggleSidebar }">
            <v-section>
                <v-section-heading>
                    <template v-slot:default>
                        {{ $route.meta.title }}
                    </template>

                    <template v-slot:description>
                        {{ $route.meta.description }}
                    </template>

                    <template v-slot:actions>
                        <div class="flex-shrink-0">
                            <!-- <v-button color="dark" :to="{ name: 'reports.index' }">
                                Do something
                            </v-button> -->
                            <template v-if="reportDownloadLink && false">
                                <v-button :href="reportDownloadLink" target="_blank">
                                    Download Report (.csv)
                                    <v-icon class="fa fa-download ml-2" />
                                </v-button>
                            </template>
                        </div>
                        <div class="block lg:hidden">
                            <v-button color="primary" @click.stop="toggleSidebar">
                                <i class="fa fa-list-ul" />
                            </v-button>
                        </div>
                    </template>
                </v-section-heading>

                <v-ghost @download-link-updated="setReportDownloadLink" />
            </v-section>
        </template>
    </v-main>
</template>

<script>
export default {
    data() {
        return {
            menu: [
                {
                    label: "Customer Message Report",
                    route: { name: "reports.customer-message-report" },
                    description: "Messages sent by day.",
                    icon: "fa fa-id-card",
                },
                {
                    label: "Orders Without Games Report",
                    route: { name: "reports.orders-without-games-report" },
                    description: "A list of orders that have order lines whose products have no games.",
                    icon: "fa fa-puzzle-piece",
                },
                {
                    label: "Recent Tips Report",
                    route: { name: "reports.tip-report" },
                    description: "Tips sent from customers to Sherpas in the last 7 days.",
                    icon: "fas fa-dollar-sign",
                },
                {
                    label: "Recent Reviews Report",
                    route: { name: "reports.review-report" },
                    description: "Customer reviews from completed orders.",
                    icon: "fas fa-star",
                },
                {
                    label: "Order Status Report",
                    route: { name: "reports.order-status" },
                    description: "Visualize job progress and cherry picking of jobs.",
                    icon: "fas fa-list-alt",
                },
                {
                    label: "How Did You Hear About Us?",
                    route: { name: "reports.order-guest-customer-surveys-report" },
                    description: "Report about lead sources.",
                    icon: "fas fa-poll",
                },
            ],
            reportDownloadLink: `${process.env.VUE_APP_API_URL}/downloads/order-status`,
        };
    },
    methods: {
        setReportDownloadLink(link) {
            this.reportDownloadLink = link;
        },
    },
};
</script>
